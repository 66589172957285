
import { useForm } from 'ant-design-vue/es/form';
import { defineComponent, reactive } from 'vue';

export default defineComponent({
  name: 'RepositoryForm',
  props: {
    showSubmit: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const validateDomain = (rule: any, value: string) => {
      return new Promise<void>((resolve, reject) => {
        const regex = /^user-(.*)$/;
        if (!regex.test(value)) {
          reject('需要以 user- 开头');
        }
        resolve();
      });
    };

    const modelRef = reactive({
      name: undefined,
      url: undefined,
      owner: undefined,
      approver: undefined,
      dateRange: [],
      type: undefined,
    });
    const rulesRef = reactive({
      name: [{ required: true, message: '请输入仓库名称' }],
      url: [{ required: true, validator: validateDomain }],
    });

    const { resetFields, validate, validateInfos } = useForm(modelRef, rulesRef);

    const handleSubmit = (e: Event) => {
      e.preventDefault();
    };

    return {
      modelRef,
      resetFields,
      validate,
      validateInfos,

      handleSubmit,
    };
  },
});
